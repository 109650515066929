import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  max-width: 1200px;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderOptions = styled.div`
  display: flex;
  gap: 20px;
`;

export const HeaderOption = styled.div`
  cursor: pointer;
`;

// export const Wrapper = styled.div`
//   border: 1px solid #000000;
//   border-radius: 5px;
//   width: 50vw;
//   padding: 10px;
// `;

// export const StyledLogo = styled.img`
//   display: block;
//   width: 20vw;
//   margin: 0 auto;
// `;

// export const Title = styled.h1`
//   text-align: center;
//   margin: 10px 0;
//   font-size: 24px;
//   font-weight: 600;
// `;

export const Intro = styled.div`
  background-color: #118c75;
  padding: 20px 0px;

  div {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
  }
  h1 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-size: 22px;
  }

  h4 {
    margin: 0;
    color: #fff;
    padding: 10px 0px;
    font-weight: normal;
    font-size: 18px;
  }
`;

export const Demand = styled.div`
  background-color: #d1d2d4;
  padding: 20px 0px;

  div.center {
    gap: 40px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
  }

  h1 {
    margin: 0;
    font-size: 22px;
  }

  h3 {
    margin: 0;
    padding: 4px 0px;
    font-weight: normal;
    font-size: 18px;
  }
`;

export const Performance = styled.div`
  background-color: #118c75;
  padding: 20px 0px;

  div.center {
    gap: 40px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
  }

  h1 {
    margin: 0;
    font-size: 22px;
    color: #fff;
  }

  h3 {
    margin: 0;
    padding: 4px 0px;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
  }
`;

export const Services = styled.div`
  padding: 20px 0px;
  max-width: 65vw;
  margin: 0 auto;
  display: block;
  width: 100%;

  h1 {
    margin: 0 0 20px;
    font-size: 22px;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;

    ul {
      padding: 0;
      margin: 0;
    }
  }
`;

export const SignUp = styled.div`
  padding: 20px 0px;
  /* max-width: 65vw; */
  margin: 0 auto;
  display: block;
  width: 100%;
  background-color: red;

  div.center {
    gap: 40px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
  }

  h1 {
    margin: 0 0 20px;
    font-size: 19px;
    text-align: center;
    color: #fff;
  }
`;

export const Companies = styled.div`
  padding: 20px 0px;
  margin: 0 auto;
  display: block;
  width: 100%;

  div.center {
    h1 {
      margin: 0 0 0px;
      font-size: 22px;
      text-align: center;
    }
    gap: 40px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
    flex-direction: column;

    div.companies {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 200px;
        height: fit-content;
      }
    }
  }

  h1 {
    margin: 0 0 20px;
    font-size: 22px;
    text-align: center;
  }
`;

export const Footer = styled.div`
  padding: 20px 0px;
  margin: 0 auto;
  display: block;
  width: 100%;
  background-color: #000;

  div.center {
    gap: 40px;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 65vw;
    justify-content: space-between;

    a {
      color: #fff;
      text-decoration: none;
    }
  }
`;
