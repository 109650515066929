import { useNavigate, Link } from "react-router-dom";
// import LoadingButton from "components/LoadingButton";
import { LOGIN } from "constants/routes";
// import Logo from "assets/images/logo.png";
import NewLogo from "assets/images/new-logo.png";
import Banner from "assets/images/banner.png";
import DemandImage from "assets/images/demand.png";
import Computer from "assets/images/computer.png";
import C1Image from "assets/images/c1.png";
import C2Image from "assets/images/c2.png";
import C3Image from "assets/images/c3.png";
import {
  Container,
  // Wrapper,
  // StyledLogo,
  // Title,
  Footer,
  Header,
  HeaderOptions,
  HeaderOption,
  Intro,
  Demand,
  Performance,
  Services,
  Companies,
  // SignUp,
} from "styles/pages/customer-type";

const CustomerTypePage = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <img src={NewLogo} alt="" style={{ width: "100px" }} />
        <HeaderOptions>
          <HeaderOption
            onClick={() => window.open("https://bottrutrac.com/login")}
          >
            Customer Login
          </HeaderOption>
          <HeaderOption onClick={() => navigate(LOGIN)}>
            Decorator Portal
          </HeaderOption>
        </HeaderOptions>
      </Header>

      <img src={Banner} alt="" style={{ width: "100%" }} />

      <Intro>
        <div>
          <h1>INTRO / ABOUT / TITLE</h1>

          <h4>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
            ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
            consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate
            velit esse molestie consequat, vel illum dolore eu feugiat nulla
            facilisis at vero eros et accumsan et iusto odio dignissim qui
            blandit praesent luptatum zzril delenit augue duis dolore te feugait
            nulla facilisi. Lorem ipsum dolor sit amet, cons ectetuer adipiscing
            elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
            magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip
            ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetuer
            adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
            dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
            quis nostrud exerci tation ullamcorper
          </h4>
        </div>
      </Intro>

      <Demand>
        <div className="center">
          <div>
            <h1>What is Print On Demand?</h1>
            <h3>
              Print On Demand [POD] is the sustainable act of printing solely
              what is needed in an extremely fast timeframe. There are no
              minimum order requirements and no set-up charges. POD is both
              Eco-Friendly and cost-effective, allowing promotional companies &
              their customers to avoid tying up money in inventory that may
              never be used. Simply put: POD allows you to order only what you
              need when you need it!
            </h3>
          </div>

          <img src={DemandImage} alt="" style={{ width: "300px" }} />
        </div>
      </Demand>

      <Performance>
        <div className="center">
          <img
            src={Computer}
            alt=""
            style={{ width: "300px", height: "200px" }}
          />
          <div>
            <h1>Key Performance Indicator</h1>
            <h3>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
              tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo
              consequat. Duis autem vel eum iriure dolor in hendrerit in
              vulputate velit esse molestie consequat, vel illum dolore eu
              feugiat nulla facilisis at vero eros et accumsan et iusto odio
              dignissim qui blandit praesent luptatum zzril delenit augue duis
              dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, cons
              ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
              ut laoreet dolore magna aliquam.
            </h3>
          </div>
        </div>
      </Performance>

      <Services>
        <h1>Who We Service</h1>
        <div>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
          </ul>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
          </ul>
          <ul>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Lorem ipsum dolor sit amet</li>
          </ul>
        </div>
      </Services>

      <Companies>
        <div className="center">
          <h1>A FAMILY OF COMPANIES SINCE 1945</h1>
          <div className="companies">
            <img src={C1Image} alt="" />
            <img src={C2Image} alt="" />
            <img src={C3Image} alt="" />
            <img src={NewLogo} alt="" />
          </div>
        </div>
      </Companies>

      <Footer>
        <div className="center">
          <a href="/">Privacy Policy</a>
          <a href="/">Terms and Conditions</a>
          <a
            target="_blank"
            href="https://bottrutrac.com/login"
            rel="noreferrer"
          >
            Customer Login
          </a>

          <Link to={LOGIN}>Decorator Portal</Link>
        </div>
      </Footer>

      {/* <SignUp>
        <div className="center">
          <h1>
            Sign up today... Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
            magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
            nostrud exerci tation
          </h1>
        </div>
      </SignUp> */}
      {/* <Wrapper>
        <StyledLogo alt="logo" src={Logo} />
        <Title>Select Customer Type</Title>
        <Footer>
          <LoadingButton
            size="large"
            label="Customer"
            variant="contained"
            onClick={() => window.open("https://bottrutrac.com/login")}
          />
          <LoadingButton
            size="large"
            label="Admin"
            variant="contained"
            onClick={() => navigate(LOGIN)}
          />
        </Footer>
      </Wrapper> */}
    </Container>
  );
};

export default CustomerTypePage;
